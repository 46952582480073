.connect-form-block .dots {
  .is-pink {
    right: 5.138%;
    top: 147px;
  }

  .is-yellow {
    right: 14.166%;
    top: 31px;
  }
}


@include touch {
  .connect-form-block .dots {
    .is-yellow {
      right: 26.229508196%;
      top: 24px;
    }
  }
}

@include mobile {
  .connect-form-block .dots {
    display: none;
  }
}
