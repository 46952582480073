$padding: 10px 8px 6px 12px;;

.language-selector {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  opacity: .9;

  &__selected {
    display: flex;
    align-items: center;
    padding: $padding;

    &__arrow {
      margin-left: 6px;
    }
  }

  &__list {
    display: none;
  }

  &.is-open {
    background: #1B2028;
    border-radius: 4px 4px 0 0;

    .language-selector__list {
      display: block;
      position: absolute;
      background: #1B2028;
      width: 100%;
      left: 0;
      top: 100%;
      border-radius: 0 0 4px 4px;
      overflow: hidden;

      &__item {
        padding: 4px 12px 6px;

        &:hover {
          background: $blue;
          color: #fff;
        }
      }
    }
  }
}
