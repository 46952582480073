/// @param {Value}          $font-size     - Font size of the text
/// @param {Unitless Value} $line-height   - Line height of the text; **must be a unitless value**
/// @param {Number}         $lines-to-show - Number of lines to show
/// @example scss
/// p {
///   @include line-clamp($font-size: 16px, $line-height: 1.5, $lines-to-show: 3);
/// }
@mixin line-clamp(
  $font-size,
  $line-height,
  $lines-to-show
) {
  display: block;
  display: -webkit-box;
  max-height: ($font-size * $line-height * $lines-to-show);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
}
