.feature.is-mobile {
  position: absolute;
  width: 20.833333333%;//300px
  top: 78px;

  .feature__images {
    //height: 356px;
    margin-bottom: 26px;
  }

  .feature__images__mobile1 {
    position: relative;
    z-index: 2;
    left: 0;
    width: 63%;
  }

  .feature__images__mobile2 {
    position: absolute;
    z-index: 1;
    left: 148px;
    top: 34px;
    width: 65%;
  }

  .feature__circle {
    bottom: -14.4708423%;
    left: -16.33333333%;
  }
}


@include tablets {
  .feature.is-mobile {
    top: 0;
    width: calc(100% - #{2*$wrapperPaddingTablets});
    padding-left: 44.262295082%;
    padding-top: 73px;

    .feature__images {
      position: absolute;
      top: 0;
      left: 0;
      width: 44.540229885%;

      &__mobile1 {
        width: 60.967741935%;
      }

      &__mobile2 {
        width: 62.903225806%;
        left: 38.387096774%;
        bottom: 32px;
        top: auto;
      }
    }

    .feature__circle {
      left: 19.396551724%;
      top: 14px;
    }

    .feature__text {
      max-width: 260px;
    }
  }
}

@include mobile {
  .feature.is-mobile {
    position: relative;
    display: flex;
    top: auto;

    .feature {
      &__text {
        flex: 0 0 48.2876712329%;
        align-self: center;
      }

      &__images {
        position: relative;
        width: 100%;
        left: auto;
        top: auto;
        margin: 0;

        &__mobile1 {
          width: 60.2649006623%;
        }

        &__mobile2 {
          width: 62.2516556291%;
          top: 0;
          right: 0;
          left: auto;
        }
      }

      &__circle {
        left: 50%;
        bottom: -29px;
        transform: translateX(-50%);
      }
    }
  }
}