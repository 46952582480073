.socials {
  display: flex;

  &__item {
    display: block;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
