$whyWeGutter: 10px;
$whyWeGutterVertical: 25px;

@include touch {
  .why-we__list {
    flex-wrap: wrap;
    margin: #{-1 * $whyWeGutterVertical} #{-1 * $whyWeGutter};
    //flex: 0 0 calc(100% - #{$whyWeGutter + $whyWeGutterVertical});

    .why-we__item {
      flex: 0 0 calc(50% - #{2 * $whyWeGutter});
      max-width: 100%;
      margin: $whyWeGutterVertical $whyWeGutter;
    }
  }
}


@include mobile {
  .why-we__list {
    flex-wrap: nowrap;
    margin: 0;
    flex-direction: column;
    margin-bottom: 50px;

    .why-we__item {
      flex: 1;
      max-width: 100%;
      margin: 0 0 40px 0;
      display: flex;
      flex-direction: row;

      &-icon {
        flex: 0 0 48px;
        height: 48px;
        margin-right: 15px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}