@include touch {
  .examples-block {
    padding: 60px 0 0;

    &__present {
      top: -67px;
      right: 38.2513661202%;
    }
  }

  .examples {
    //width: calc(100% + #{$wrapperPaddingTablets});

    .splide__arrows {
      right: $wrapperPaddingTablets;
    }
  }
}

@include mobile {
  .examples {
    width: 100%;

    .dots {
      display: none;
    }
  }

  .examples-block__present {
    position: absolute;
    right: 33.125%;
    top: -48px;
  }

  .examples-item {
    height: 408px;
  }
}
