.write-us {
  display: flex;
  flex-direction: row;
  color: #FFFFFF;
  font-style: normal;
  background: linear-gradient(93.34deg, #4F3DFE -22.89%, #A93DFE 115.46%);
  border-radius: 10px;
  height: 171px;
  position: relative;

  &__title {
    font-family: Bebas Neue, sans-serif;
    font-weight: bold;
    font-size: 40px;
    line-height: 100%;
    margin-bottom: 17px;
  }

  &__subtitle {
    max-width: 363px;
  }

  &__content {
    flex: 1;
    align-self: center;
    padding: 39px 37px 31px;
  }

  &__icon {
    position: relative;
    top: -8px;
  }

  &__button {
    padding: 30px 30px 30px 7.713%;
    align-self: flex-end;
  }

  &__present.present {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
  }
}

.write-us-block {
  position: relative;
  z-index: 2;
}

@include touch {
  .write-us {
    flex-direction: column;
    height: auto;
    padding: 34px 20px 20px;

    &__content {
      padding: 0;
      align-self: flex-start;
      margin-bottom: 25px;
    }

    &__button {
      align-self: start;
      padding: 0;
    }

    &__icon {
      position: absolute;
      right: 7.7868852459%;
      top: 17px;
    }
  }
}


@include mobile {
  .write-us {
    padding: 20px;

    &__icon {
      position: static;
      order: 1;
      margin-bottom: 20px;
    }

    &__content {
      order: 2;
      margin-bottom: 30px;
    }

    &__button {
      order: 3;
    }

    &__present.present {
      width: 62px;
      height: 62px;
      bottom: auto;
      top: -15px;
      right: -15px;
      left: auto;
      transform: none;

      .present__inner {
        width: 40px;
        height: 40px;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}