$desktop: 1024px;
$tablet: 768px;

@mixin touch() {
  @media screen and (max-width: $desktop - 1px) {
    @content
  }
}

@mixin tablets() {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content
  }
}

@mixin mobile() {
  @media screen and (max-width: $tablet - 1px) {
    @content
  }
}

@mixin smallMobile() {
  @media screen and (max-width: 320px){
    @content
  }
}
