/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/Montserrat-Thin.woff2") format("woff2"),
  url("../fonts/Montserrat-Thin.woff") format("woff");
}

/** Montserrat Thin-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: italic;
  src: url("../fonts/Montserrat-ThinItalic.woff2") format("woff2"),
  url("../fonts/Montserrat-ThinItalic.woff") format("woff");
}

/** Montserrat ExtraLight **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/Montserrat-ExtraLight.woff2") format("woff2"),
  url("../fonts/Montserrat-ExtraLight.woff") format("woff");
}

/** Montserrat ExtraLight-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: italic;
  src: url("../fonts/Montserrat-ExtraLightItalic.woff2") format("woff2"),
  url("../fonts/Montserrat-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Light **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Montserrat-Light.woff2") format("woff2"),
  url("../fonts/Montserrat-Light.woff") format("woff");
}

/** Montserrat Light-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/Montserrat-LightItalic.woff2") format("woff2"),
  url("../fonts/Montserrat-LightItalic.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2"),
  url("../fonts/Montserrat-Regular.woff") format("woff");
}

/** Montserrat Regular-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/Montserrat-Italic.woff2") format("woff2"),
  url("../fonts/Montserrat-Italic.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/Montserrat-Medium.woff2") format("woff2"),
  url("../fonts/Montserrat-Medium.woff") format("woff");
}

/** Montserrat Medium-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: italic;
  src: url("../fonts/Montserrat-MediumItalic.woff2") format("woff2"),
  url("../fonts/Montserrat-MediumItalic.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2"),
  url("../fonts/Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat SemiBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/Montserrat-SemiBoldItalic.woff2") format("woff2"),
  url("../fonts/Montserrat-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Montserrat-Bold.woff2") format("woff2"),
  url("../fonts/Montserrat-Bold.woff") format("woff");
}

/** Montserrat Bold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/Montserrat-BoldItalic.woff2") format("woff2"),
  url("../fonts/Montserrat-BoldItalic.woff") format("woff");
}

/** Montserrat ExtraBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/Montserrat-ExtraBold.woff2") format("woff2"),
  url("../fonts/Montserrat-ExtraBold.woff") format("woff");
}

/** Montserrat ExtraBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: italic;
  src: url("../fonts/Montserrat-ExtraBoldItalic.woff2") format("woff2"),
  url("../fonts/Montserrat-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Black **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/Montserrat-Black.woff2") format("woff2"),
  url("../fonts/Montserrat-Black.woff") format("woff");
}

/** Montserrat Black-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/Montserrat-BlackItalic.woff2") format("woff2"),
  url("../fonts/Montserrat-BlackItalic.woff") format("woff");
}

/** =================== MONTSERRAT ALTERNATES =================== **/

/** Montserrat Alternates Thin **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/MontserratAlternates-Thin.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-Thin.woff") format("woff");
}

/** Montserrat Alternates Thin-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 100;
  font-style: italic;
  src: url("../fonts/MontserratAlternates-ThinItalic.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-ThinItalic.woff") format("woff");
}

/** Montserrat Alternates ExtraLight **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/MontserratAlternates-ExtraLight.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-ExtraLight.woff") format("woff");
}

/** Montserrat Alternates ExtraLight-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 200;
  font-style: italic;
  src: url("../fonts/MontserratAlternates-ExtraLightItalic.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Alternates Light **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/MontserratAlternates-Light.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-Light.woff") format("woff");
}

/** Montserrat Alternates Light-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 300;
  font-style: italic;
  src: url("../fonts/MontserratAlternates-LightItalic.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-LightItalic.woff") format("woff");
}

/** Montserrat Alternates Regular **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/MontserratAlternates-Regular.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-Regular.woff") format("woff");
}

/** Montserrat Alternates Regular-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 400;
  font-style: italic;
  src: url("../fonts/MontserratAlternates-Italic.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-Italic.woff") format("woff");
}

/** Montserrat Alternates Medium **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/MontserratAlternates-Medium.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-Medium.woff") format("woff");
}

/** Montserrat Alternates Medium-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 500;
  font-style: italic;
  src: url("../fonts/MontserratAlternates-MediumItalic.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-MediumItalic.woff") format("woff");
}

/** Montserrat Alternates SemiBold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/MontserratAlternates-SemiBold.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-SemiBold.woff") format("woff");
}

/** Montserrat Alternates SemiBold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 600;
  font-style: italic;
  src: url("../fonts/MontserratAlternates-SemiBoldItalic.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Alternates Bold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/MontserratAlternates-Bold.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-Bold.woff") format("woff");
}

/** Montserrat Alternates Bold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 700;
  font-style: italic;
  src: url("../fonts/MontserratAlternates-BoldItalic.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-BoldItalic.woff") format("woff");
}

/** Montserrat Alternates ExtraBold **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/MontserratAlternates-ExtraBold.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-ExtraBold.woff") format("woff");
}

/** Montserrat Alternates ExtraBold-Italic **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 800;
  font-style: italic;
  src: url("../fonts/MontserratAlternates-ExtraBoldItalic.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Alternates Black **/
@font-face {
  font-family: "Montserrat Alternates";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/MontserratAlternates-Black.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-Black.woff") format("woff");
}

/** Montserrat Alternates Black-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: italic;
  src: url("../fonts/MontserratAlternates-BlackItalic.woff2") format("woff2"),
  url("../fonts/MontserratAlternates-BlackItalic.woff") format("woff");
}

//Bebes Neue
@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue Thin'), local('BebasNeue-Thin'), url('../fonts/Bebes/bebasneuethin.woff2') format('woff2'), url('../fonts/Bebes/bebasneuethin.woff') format('woff'), url('../fonts/Bebes/bebasneuethin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue Light'), local('BebasNeue-Light'), url('../fonts/Bebes/bebasneuelight.woff2') format('woff2'), url('../fonts/Bebes/bebasneuelight.woff') format('woff'), url('../fonts/Bebes/bebasneuelight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue Book'), local('BebasNeueBook'), url('../fonts/Bebes/bebasneuebook.woff2') format('woff2'), url('../fonts/Bebes/bebasneuebook.woff') format('woff'), url('../fonts/Bebes/bebasneuebook.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue Regular'), local('BebasNeueRegular'), url('../fonts/Bebes/bebasneueregular.woff2') format('woff2'), url('../fonts/Bebes/bebasneueregular.woff') format('woff'), url('../fonts/Bebes/bebasneueregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue Bold'), local('BebasNeueBold'), url('../fonts/Bebes/bebasneuebold.woff2') format('woff2'), url('../fonts/Bebes/bebasneuebold.woff') format('woff'), url('../fonts/Bebes/bebasneuebold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
