@include touch {
  .roi-nps-block {
    padding: 60px 0 70px;
  }

  .roi-nps-items {
    //width: calc(100% + #{$wrapperPaddingTablets});

    .roi-nps-item__icon {
      background-size: contain !important;
    }

    .splide__arrows {
      display: flex;
      right: $wrapperPaddingTablets;
    }
  }
}

@include mobile {
  .roi-nps-items {
    width: 100%;
  }

  .roi-nps-block {
    padding: 60px 0 0;

    .dots {
      display: none;
    }

    h2 {
      max-width: 140px;
    }
  }

  .roi-nps-item__description {
    @include line-clamp(12px, 1.5, 9);
  }
}
