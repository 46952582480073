@import "./mixins";
@import "./variables";
@import "./font";

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body, html {
  padding: 0;
  margin: 0;
}

body {
  background: $backgroundColor;
  font-size: 14px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
}

main {
  max-width: 100%;
  //overflow: hidden;
}

.wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 $wrapperPadding;
  position: relative;
}


h2 {
  font-family: Bebas Neue, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  color: #FFFFFF;
  margin-bottom: 0.625em;
  margin-top: 0;
}

.text-normal {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #fff;
}

.dark-plate {
  background: linear-gradient(130.5deg, #282E37 4.23%, rgba(27, 32, 40, 0) 105.89%);
  box-shadow: inset 15px 10px 50px rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  border: 1px solid #676a6f;
  //border-image-source: linear-gradient(140.09deg, #4B4B4B 0%, rgba(255, 255, 255, 0) 101.46%);
}

.text-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #fff;
}

.text-gray {
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #FFFFFF;
  opacity: 0.5;
}

.is-relative {
  position: relative;
}

.section {
  position: relative;
}

.grecaptcha-badge {
  visibility: hidden;
}

@import "./aggregator";

@include touch {
  .wrapper {
    padding: 0 $wrapperPaddingTablets;
  }

  .text-normal {
    font-size: 14px;
  }

  body {
    font-size: 12px;
  }

  .is-hide-on-mobile {
    display: none;
  }

  .main {
    overflow-x: hidden;
  }
}

@include mobile {
  .wrapper {
    padding: 0 $wrapperPaddingMobile;
  }

  .text-gray {
    font-size: 10px;
  }

  h2 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  .text-title {
    font-size: 14px;
    line-height: 140%;
  }

  .text-normal {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
  }
}
