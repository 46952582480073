.roi-nps-block {
  padding: 100px 0 20px;
}

.roi-nps-items {
  //display: flex;
  //justify-content: space-between;
  //width: calc(100% + 10px);
  //margin: 0 -10px;
  z-index: 2;

  .splide__arrows {
    display: none;
  }
}

.roi-nps-item {
  flex: 1;
  margin: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 490px;// 100%;

  &:hover {
    background: linear-gradient(93.34deg, #4F3DFE -22.89%, #A93DFE 115.46%);
    position: relative;
    border-color: #a075cc;
  }

  &__title {
    margin-bottom: 15px;
  }

  &__content {
    flex: 1;
  }

  &__description {
    margin-bottom: 24px;
    @include line-clamp(15px, 1.5, 7);
  }

  &__icon {
    width: 100%;
    //@todo height in %
    height: 203px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: contain;
    }
  }

  &.is-analytics {
    .roi-nps-item__icon {
      img {
        width: 86.9822485207%;
        height: 92.6108374384%;
      }
    }
  }

  &.is-gamification {
    .roi-nps-item__icon {
      img {
        width: 53.8461538462%;
        height: 70.9359605911%;
      }
    }
  }

  &.is-integration {
    .roi-nps-item__icon {
      img {
        width: 63.9053254438%;
        height: 86.2068965517%;
      }
    }
  }
}

.roi-nps-block .splashes {
  .is-bright-yellow {
    top: 186px;
    left: -636px;
  }
}

@import "./mobile";
