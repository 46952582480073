.directions-menu__hamburger {
  width: 20px;
  height: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    background: rgba(0,0,0,0);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__inner {
    width: 100%;
    height: 2px;
    background: #111111;
    border-radius: 10px;
  }
}
