.main-block .dots {
  .is-yellow-1 {
    left: -8px;
    top: 159px;
  }

  .is-yellow-2 {
    right: 29.861%;
    bottom: 127px;
  }

  .is-yellow-3 {
    bottom: 277px;
    right: 14.513%;
  }

  .is-yellow-4 {
    left: 57.5%;
    top: 471px;
  }

  .is-yellow-5 {
    top: 57px;
    left: 24.375%;
  }

  .is-pink-1 {
    top: 118px;
    left: 15.069%;
  }

  .is-pink-2 {
    top: 231px;
    left: 38.75%;
  }

  .is-pink-3 {
    top: 129px;
    right: 18.263%;
  }

  .is-pink-4 {
    bottom: 44px;
    left: 20.902%;
  }
}

@include touch {
  .main-block .dots {
    .is-yellow-5 {
      top: 27px;
      left: 11.848958333%;
    }

    .is-pink-3 {
      top: 222px;
      right: 17.578125%;
    }

    .is-yellow-2 {
      right: 22.1354166667%;
      bottom: 59px;
    }

    .is-pink-4, .is-pink-1, .is-pink-2, .is-yellow-4, .is-yellow-3, .is-yellow-1 {
      display: none;
    }
  }
}

@include mobile {
  .main-block .dots {
   .is-yellow-5 {
      top: 31px;
     left: -1px;
   }

    .is-pink-3 {
      top: 15px;
      right: 21.875%;
      width: 8px;
      height: 8px;
    }

    .is-yellow-2 {
      display: none;
    }
  }
}