.header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px 0;
  position: relative;
  z-index: 2;

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo {
      img {
        height: 33px;
      }
    }
  }

  .dots {
    .is-yellow {
      right: 28.559%;
      bottom: -8px;
    }
  }

  .language-selector {
    margin-right: 20px;
  }

  &__right {
    display: flex;
    align-items: center;
  }
}

@include mobile {
  .header {
    width: 100%;
    padding: 11px 0 0;
    .wrapper {
      flex-wrap: wrap;
      justify-content: space-between;

      .logo {
        order: 1;
        width: 50%;
      }

      .demo-button {
        order: 2;
      }

      .nav {
        order: 3;
      }
    }
    .dots {
      display: none;
    }

    .language-selector {
      display: none;
    }
  }
}
