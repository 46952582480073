.connect-form {
  &__points {
    width: 42.457%;
    background: linear-gradient(180deg, #FD792E 0%, #F39A27 59.6%);

    color: #fff;
    padding: 42px 36px 54px;
    position: relative;
    overflow: hidden;

    &__bg {
      position: absolute;
      left: -7px;
      top: 0;
      height: 100%;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__content {
      position: relative;
    }
  }

  &__icon {
    width: 148px;
    height: 82px;
    background: url("../images/connect-form/present.svg") no-repeat center center;
    margin-bottom: 30px;
  }

  &__points-number {
    font-family: Bebas Neue, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 45px;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
}


@include touch {
  .connect-form {
    &__points {
      width: 35.5191256831%;
      padding: 40px 20px 23px;

      &__bg {
        position: absolute;
        left: auto;
        top: auto;
        bottom: 0;
        height: auto;
        width: 168.199233716%;
        right: -19.615384615%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__points-number {
      margin-bottom: 20px;
    }

    &__description.text-normal {
      font-size: 12px;
      line-height: 150%;
    }
  }
}

@include mobile {
  .connect-form {
    &__points {
      width: 100%;
      padding: 20px;
    }
  }
}
