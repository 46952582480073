.directions-menu__item {
  list-style: none;

  &__arrow {
    display: none;
  }

  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #111;
    text-decoration: none;
    padding: 15px $menuSidePadding;
    display: inline-block;
    width: 100%;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: calc(100% - #{$menuSidePadding * 2});
      height: 1px;
      background: rgba(0, 0, 0, 0.15);
      position: absolute;
      bottom: 1px;
      left: $menuSidePadding;
    }

    &:hover {
      color: $carrot
    }
  }

  &.is-active {
    background: #F4F4F4;
    position: relative;
    cursor: default;

    &:before {
      content: "";
      display: block;
      width: 4px;
      height: 100%;
      background: $carrot;
      position: absolute;
      left: 0;
      top: 0;
    }

    a {
      color: $carrot;
      font-weight: 600;
      cursor: default;
    }
  }
}

@include touch {
  .directions-menu__item {
    position: relative;

    &__arrow {
      display: block;
      width: 6px;
      height: 12px;
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
