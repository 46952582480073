.footer {
  padding: 30px 0;
  position: relative;

  img {
    height: 33px;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .splashes {
    .is-pink {
      top: -494px;
      right: -236px;
    }
  }

  .language-selector {
    display: none;
  }
}

@include mobile {
  .footer {
    .wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__logo {
      order: 1;
    }

    &__nav {
      order: 3;
    }

    .socials {
      order: 2;
    }

    .language-selector {
      display: initial;
      order: 1;
    }
  }
}
