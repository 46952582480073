.connect-form {
  &__fields {
    background: #151920;
    padding: 52px 40px 49px 48px;
    flex: 1;
  }

  &__title {
    font-family: Bebas Neue, sans-serif;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    text-transform: uppercase;
    margin: 0 0 35px;
  }

  &__submit {
    margin-top: 11px;
  }
}

@include touch {
  .connect-form {
    &__fields {
      padding: 48px 20px 49px 44px;
    }

    &__title {
      margin: 0 0 33px;
    }

    &__submit {
      margin-top: 11px;
    }
  }
}

@include mobile {
  .connect-form {
    &__fields {
      padding: 30px 20px 20px;
    }

    &__title {
      margin: 0 0 20px;
    }

    &__submit {
      margin-top: 20px;
      width: 100%;
    }
  }
}
