.form-group {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 12px);
  margin: 0 -12px 29px;

  &__item {
    flex: 1;
    margin: 0 12px;
  }
}

@include touch {
  .form-group {
    display: flex;
    justify-content: space-between;
    width: calc(100% + 10px);
    margin: 0 -10px 30px;

    &__item {
      flex: 1;
      margin: 0 10px;
    }
  }
}


@include mobile {
  .form-group {
    width: 100%;
    margin: 0;
    flex-direction: column;

    &__item {
      width: 100%;
      margin: 0 0 30px;
    }
  }
}
