.connect-form-block {
  padding: 100px 0 62px;
  z-index: 2;

  &.is-hidden {
    display: none;
  }

  &.is-modal {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .dots, .splashes {
      display: none;
    }

    .connect-form {
      position: relative;
    }

    .connect-form-close {
      display: block;
      position: absolute;
      right: #{$wrapperPadding - 30px};
      top: -17px;
      cursor: pointer;

      &:before {
        content: "";
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        left: -5px;
        top: -5px;
        background: rgba(0,0,0,0);
      }

      &:hover {
        svg g {
          opacity: 0.8;
        }
      }
    }
  }
}

.connect-form {
  display: flex;
  border-radius: 10px;
  overflow: hidden;

  &__agreement {
    color: #fff;
    padding: 0 12px;

    a {
      color: #fff;
    }
  }

  &__errors {
    color: #E01931;
  }

  &__spinner {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }

  &__submit{
    position: relative;
    transition: all 0.2s ease;

    &.is-loading {
      padding-right: 40px;
      background: lighten($blue, 10);
      cursor: default;


      .connect-form__spinner {
          display: block;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.connect-form-close {display: none}

@import "points";
@import "fields";
@import "dots";
@import "splashes";

@include touch {
  .connect-form-block {
    &.is-modal {
      .connect-form-close {
        right: #{$wrapperPaddingTablets - 30px};
      }
    }
  }
}

@include mobile {
  .connect-form-block {
    padding: 60px 0 0;
  }

  .connect-form {
    flex-direction: column;
  }

  .connect-form-block {
    &.is-modal {
      padding: 0;
      z-index: 100;

      .wrapper {
        padding: 0;
        height: 100%;
        overflow: hidden scroll;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .connect-form-close {
        right: 5px;
        top: 5px;
        z-index: 20;
        position: fixed;
      }

      .connect-form {
        border-radius: 0;
      }
    }
  }

  .connect-form__submit {
    &.is-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 25px;

      .connect-form__spinner {
        position: relative;
        transform: none;
        top: auto;
        right: auto;
        margin-left: 8px;
      }
    }
  }
}
