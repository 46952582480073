@import "dots";

.examples-block {
  padding: 100px 0 20px;

  .splashes {
    .is-bright-yellow {
      right: 151px;
      top: -104px;
      opacity: 0.8;
      filter: blur(77.6795px);
    }
  }
}

.examples-block__present {
  position: absolute;
  right: 20.972%;
  top: -54px;
}

.examples-item {
  width: 100%;//380px;
  position: relative;
  overflow: hidden;
  //height: 100%;
  height: 511px;
  display: flex;
  flex-direction: column;

  &:hover {
    .examples-item__top {
      height: 98px;
    }

    .examples-item__bottom {
      padding-bottom: 20px;
    }

    .examples-item__description {
      display: block;
      max-height: 100%;
      height: 100%;
      overflow: hidden;
    }

    .examples-item__logo {
      transform: translate(-50%, -50%) scale(0.5);
      //width: 120px;
      //height: 60px;
    }
  }

  &:after {
    content: "";
    display: block;
    height: 14px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(30, 35, 44, 0) 0, rgba(30, 35, 44, 0.6) 50%, #1E232C 100%);
    //filter: blur(2px);
  }

  &__top {
    height: 230px;
    transition: height 0.3s ease;
    transform: translateZ(0);
    overflow: hidden;
  }

  &__bottom {
    padding: 20px 20px 0;
    flex: 1;
    overflow: hidden;

    &__inner {
      height: 100%;
      overflow: hidden;
    }
  }

  &__logo {
    width: 200px;
    height: 100px;
    background: #FFFFFF;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    transition: all .2s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__image {
    width: 100%;
    height: 230px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    flex-shrink: 0;
    margin-bottom: 15px;
    @include line-clamp(16px, 1.4, 2);
    //height: (16px * 1.4 * 2);
  }

  &__more {
    position: absolute;
    left: 14px;
    bottom: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 41px;
    height: 12px;
    padding: 8px;
    border-radius: 2px;
    //background: rgba(30, 35, 44, 0.9);
    background: linear-gradient(130.5deg, #282E37 4.23%, rgba(27, 32, 40, 0.7) 105.89%);
    text-decoration: none;
    appearance: none;
  }

  &__description {
    transform: translateZ(0);
    //@include line-clamp(15px, 1.5, 8);
  }
}

.examples {
  //width: calc(100% + #{$wrapperPadding});

  //.splide__arrows {
  //  right: $wrapperPadding;
  //}
}

@import "./mobile";
