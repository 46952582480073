.button {
  border: 0;
  border-radius: 5px;
  background: $blue;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  color: #FFFFFF;
  cursor: pointer;
  padding: 10px 25px;

  &:hover {
    background: darken($blue, 10);
    outline: none;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &.is-white-bg {
    background: #fff;

    //&:hover {
    //  text-decoration: underline;
    //}
  }


  &.is-purple-text {
    color: $purple;
  }

  &.is-dark-text {
    color: $darkText;
  }

  &.is-purple-on-hover:hover {
    color: $purple;
  }
}
