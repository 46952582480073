.plan-row {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  background: #fff;

  &__item {
    display: flex;
    align-items: center;
  }
}

.plan-header {
  position: sticky;
  top: 0;
  z-index: 2;

  &.is-sticky {
      border-bottom: $propertyBorder;

      .plan {
        border-right: $propertyBorder;
      }
  }
}

@include mobile {
  .plan-header {
    display: none;
  }

  .plan-row {
    display: none;
  }
}
