@keyframes flashing {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    transform: scale(1.25);
    opacity: 0;
  }
}

.present {
  width: 90px;
  height: 90px;
  position: relative;
  cursor: pointer;
  transition: all .6s ease;

  &.is-done {
    transform: scale(0);
    //bottom: 50% !important;
    opacity: 0;
  }

  &__outer {
    width: 100%;
    height: 100%;
    border-radius: 90px;
    background: linear-gradient(180deg, #FD792E 0%, #F39A27 59.6%);
    opacity: 0.3;
    box-shadow: 0 0 10px rgba(243, 154, 39, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    transform: translateZ(0);
    animation-duration: 3s;
    animation-name: flashing;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
  }

  &__inner {
    width: 59px;
    height: 59px;
    border-radius: 59px;
    background: linear-gradient(180deg, #FD792E 0%, #F39A27 59.6%);
    box-shadow: 0 0 10px rgba(243, 154, 39, 0.3);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transition: all .5s ease;
    }
  }

  .points {
    display: none;
  }

  &.is-activated {
    cursor: default;

    .present__inner, .present__outer {
      opacity: .5;
    }

    .present__outer {
      transform: translateZ(0);
    }

    .present__inner {
      svg {
        transform: scale(0);
      }
    }

    .points {
      display: flex;
      animation: pointsScaleUp .5s ease;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.present.is-small {
  width: 52px;
  height: 52px;

  .present__inner {
    width: 30px;
    height: 30px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}


@keyframes pointsScaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@include mobile {
  .present {
    width: 60px;
    height: 60px;

    &__inner {
      width: 40px;
      height: 40px;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    &.is-right-edge .points {
      right: 0;
      left: -14px;
    }
  }
}
