$playBackground: linear-gradient(180deg, #1247FF 0%, #289FA7 100%);

.play {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 2;

  &:before {
    content: "";
    animation: pulse 3s infinite ease;
    transform: translateZ(0);
  }

  &:hover {
    .play__core {
      svg {
        transform: scale(1.2);
      }
    }
  }

  &__core, &__middle, &__outer, &:before {
    background: $playBackground;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
  }

  &__middle {
    will-change: transform, opacity;
    transform: scale(0);
    animation: pulse 3s .5s infinite ease;
  }

  &__outer {
    will-change: transform, opacity;
    transform: scale(0);
    animation: pulse 3s 1s infinite ease;
  }

  &__core svg {
    transition: all .2s ease;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform: scale(2.32);
    opacity: 0;
  }
}

//@keyframes pulseOuter {
//  0% {
//    opacity: 1;
//    transform: scale(0);
//  }
//
//  90% {
//    transform: scale(2.32);
//    opacity: 0.3;
//  }
//
//  100% {
//    transform: scale(1);
//    opacity: 0;
//  }
//}
