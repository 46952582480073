.points {
  position: absolute;
  width: 126px;
  height: 106px;
  display: flex;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  padding-left: 16px;
  transition: all .3s ease;
  white-space: nowrap;

  &__text {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #fff;
    text-transform: uppercase;
  }

  &__number {
    display: inline-block;
    min-width: 1.7em;
  }

  &__star {
    position: absolute;
    transform: translateZ(0);

    &.is-n-1 {
      left: 7px;
      top: 7px;
      animation: starScaleDown 3s linear infinite alternate;
      //animation: starScale 3s infinite;
    }

    &.is-n-2 {
      width: 14px;
      height: 14px;
      right: 6px;
      top: 23px;
      animation: starScaleUp 3s linear infinite alternate;
    }

    &.is-n-3 {
      width: 16px;
      height: 16px;
      left: 24px;
      bottom: 1px;
      animation: starScaleUp 3s 1s linear infinite alternate;
    }

    &.is-n-4 {
      width: 18px;
      height: 18px;
      right: 3px;
      bottom: 16px;
      animation: starScaleDown 3s 1s linear infinite alternate;
    }
  }
}

@keyframes floating {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-5px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes starScaleDown {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.5);
  }
}

@keyframes starScaleUp {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}
