.plan {
  font-size: 15px;
  line-height: 140%;
  padding: 1em;

  &> * {
    margin-bottom: 10px;
  }

  &__name {
    font-weight: 600;
    color: #000000;
  }

  &__price {
    color: $blue;
    font-weight: 500;
  }

  &__number {
    font-weight: bold;
    font-size: 40px;
    line-height: 140%;
  }

  &__description {
    font-size: 12px;
    line-height: 140%;
    color: #000000;
  }

  &__link {
    a {
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: $blue;
      text-decoration: none;

      &:hover {
        color: darken($blue, 10);
      }
    }
  }
}

@include touch {
  .plan {
    font-size: 14px;
    line-height: 140%;

    &__number {
      font-size: 34px;
      line-height: 160%;
    }

    &__price {
      max-width: 3em;
      margin-bottom: 14px;
    }

    &__slash {
      display: none;
    }

    &__description {
      display: none;
    }
  }
}

@include mobile {
  .plan {
    font-size: 12px;
    line-height: 160%;

    &__name {
      margin-bottom: 5px;
    }

    &__number {
      font-size: 34px;
      line-height: 160%;
    }

    &__price {
      max-width: 100%;
      margin-bottom: 0;
      font-size: 12px;
      line-height: 160%;
    }

    &__slash {
      display: inline;
    }

    &__description {
      display: block;
    }
  }
}