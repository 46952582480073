.plan-property {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .plan-row__item {
    padding: 20px 15px 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    .property-boolean {
      margin-left: 45px;
    }
  }

  &:last-child {
    border-bottom: 0;
  }
}

.plan-property .plan-property__first {
  padding: 20px 40px 15px 0;
  border-left: 0;
}
