.list {
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 4px;
    list-style: none;
    position: relative;
    display: flex;
    padding-left: 20px;

    &:before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: #fff;
      margin-right: 14px;
      position: absolute;
      left: 0;
      top: 0.45em;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.directions-list {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    position: relative;
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.is-2-column {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 350px);
    gap: 10px;

    li {
      margin: 0;
    }
  }

  &:is(ul) {
    li {
      &:before {
        content: "";
        display: inline-block;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIxLjM1MTYgMC4xODQ1N0wxMC45MjA5IDExLjc2OTZMMTUuMDU2NiAyLjg4ODQzTDAuNTU2NjQxIDAuMTg0NTcxTDguNjI2MDEgMC4yMDc4NEwyMS4zNTE2IDAuMTg0NTdaIiBmaWxsPSIjRkE4QjNEIi8+Cjwvc3ZnPgo=");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        flex: 0 0 14.6px;
        width: 14.6px;
        height: 19.35px;
        margin-right: 7px;
        align-self: flex-start;
      }
    }
  }

  &:is(ol) {
    li {
      counter-increment: olInc;

      &:before {
        content: counter(olInc) " ";
        width: 20px;
        height: 20px;
        border-radius: 20px;
        background: $carrot;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 200%;
        margin-right: 10px;
        color: #fff;
        align-self: flex-start;
      }
    }
  }
}

@include mobile {
  .directions-list {
    &.is-2-column {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }
}