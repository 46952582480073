$menuSidePadding: 30px;

@import "menu-item";
@import "menu-hamburger";

.directions-menu {
  flex: 0 0 360px;
  width: 360px;
  min-height: 100%;
  background: #FAFAFA;

  &__items {
    padding: 0;
    margin: 0;
  }

  &__bar {
    display: none;
  }

  &__close {
    display: none;
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
    border: 0;
    width: 14px;
    height: 14px;
    position: absolute;
    top: 10px;
    left: 256px;
    padding: 0;
    z-index: 1;

    &:active, &:focus {
      outline: none;
    }
  }
}

@include touch {
  .directions-menu {
    min-height: 0;
    width: 100%;
    flex: 1;
  }

  .directions-menu__bar {
    display: block;
    padding: 18px;
  }

  .directions-menu__block {
    position: fixed;
    display: none;
    opacity: 0;

    .directions-menu__items {
      width:280px;
      height: 100%;
      overflow: auto;
      background: #FAFAFA
    }

    &.is-shown {
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.6);
      z-index: 100;
      transition: opacity .2s ease;

      .directions-menu__close {
        display: block;
      }
    }

    &.is-animated {
      opacity: 1;
    }
  }
}

@include mobile {
  .directions-menu__block {
    .directions-menu__items {
      width: 100%;
    }

    .directions-menu__close {
      right: 10px;
      left: auto;
    }
  }
}
