.dot {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 32px;

  &.is-small {
    width: 12px;
    height: 12px;
  }

  &.is-medium {
    width: 20px;
    height: 20px;
  }

  &.is-big {
    width: 26px;
    height: 26px;
  }

  &.is-tiny {
    width: 6px;
    height: 6px;
  }

  &.is-yellow {
    background: linear-gradient(180deg, #FD792E 0%, #F39A27 59.6%);
    box-shadow: 0 0 10px rgba(243, 154, 39, 0.3);
  }

  &.is-pink {
    background: linear-gradient(90deg, #DC2B49 0%, #4539D7 215.62%);
    box-shadow: 0px 0px 10px rgba(186, 46, 105, 0.3);
  }
}

.dots {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .wrapper {
    position: relative;
    height: 100%;
  }
}
