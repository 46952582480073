.plan-mobile {
  display: none;
  border-bottom: $propertyBorder;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;

  .splide__pagination {
    position: absolute;
    width: 100%;
    padding: 0;
    bottom: 15px;

    li {
      width: 6px;
      height: 6px;
      flex: 0 0 6px;
      margin-right: 10px;
      line-height: 0;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        left: 50%;
        top: 50%;
        background: rgba(0,0,0,0);
        transform: translate(-50%, -50%);
        z-index: -1;
      }

      &:last-child {
        margin-right: 0;
      }

      button {
        width: 100%;
        height: 100%;
        padding: 0;
        box-shadow: none;
        border: none;
        -webkit-appearance: none;
        border-radius: 6px;
      }
    }
  }

  .plan {
    padding-bottom: 26px;
  }

  .splide__arrows, .splide__arrows:disabled, .splide__arrows:hover {
    flex-direction: column;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    .splide__arrow {
      width: 20px;
      height: 20px;
      background: #FFFFFF;
      border: 1px solid $blue;
      box-sizing: border-box;
      box-shadow: none;
      margin: 0;
      padding: 0;

      &:first-child {
        margin-bottom: 5px;
      }

      svg {
        width: 3px;
        height: 6px;

        path {
          fill: $blue;
        }
      }
    }
  }
}

.plan-mobile .splide__pagination button.splide__pagination__page {
  border: 1px solid $blue;
  background: #fff;

  &.is-active {
    background: $blue;
  }
}


@include mobile {
  .plan-mobile {
    display: block;
  }
}
