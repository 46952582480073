$categoryBorder: 1px solid rgba(0, 0, 0, 0.8);
$propertyBorder: 1px solid rgba(0, 0, 0, 0.1);

.prices {
  background: #fff;
  min-height: calc(100vh - 171px);
  padding: 65px 0;

  &__title {
    font-family: Bebas Neue, sans-serif;
    font-weight: bold;
    font-size: 65px;
    line-height: 65px;
    color: #111111;
    position: absolute;
    left: $wrapperPadding;
    top: 5px;
    z-index: 10;
  }
}

@include touch {
  .prices {
    padding: 25px 0;

    &__title {
      top: 15px;
      left: $wrapperPaddingTablets;
    }
  }
}

@include mobile {
  .prices {
    padding: 40px 0;

    &__title {
      position: static;
      margin-bottom: 10px;
      font-size: 34px;
      line-height: 100%;
    }
  }
}

@import "./prices/plans";
@import "./prices/plan-row";
@import "./prices/properties";
@import "./prices/category";
@import "./prices/property-boolean";
@import "./prices/plan-mobile";
@import "./prices/properties-mobile";
