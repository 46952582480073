.notification {
  position: fixed;
  background: lighten($backgroundColor, 5);
  padding: 24px;
  left: 50%;
  top: 50%;
  color: #fff;
  border-radius: 8px;
  font-weight: 500;
  z-index: 100;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease;
  display: none;

  &.is-shown {
    display: flex;
  }

  &.is-animated {
    opacity: 1;
  }

  &__close {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      background: rgba(0,0,0,0);
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
    margin-right: 8px;

    &.is-success {
      svg path {
        fill: #5aad5c;
      }
    }
  }

  &__text {
    display: flex;
    align-items: center;
  }
}


@include mobile {
  .notification {
    width: calc(100% - 20px);
  }
}