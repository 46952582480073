.why-we-block .dots {
  .is-yellow-1 {
    left: 3.819%;
    top: 230px;
  }
}


@include touch {
  .why-we-block .dots {
    display: none;
  }
}