.star {
  width: 26px;
  height: 26px;
  background: url("../images/star.svg") no-repeat center center;
  background-size: 100% 100%;

  &.is-tiny {
    width: 14px;
    height: 14px;
  }

  &.is-small {
    width: 16px;
    height: 16px;
  }

  &.is-medium {
    width: 18px;
    height: 18px;
  }
}
