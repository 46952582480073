.directions {
  background: #fff;

  &__wrapper {
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
  }
}

.directions-content {
  color: $directionsContentColor;
  padding: 44px 2.68817204301% 0 12.1863799283%;
  min-width: 0;
  flex: 1;
}

.directions-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 140%;
  margin: 0 0 30px 0;
  color: $directionsContentColor;

}

.directions-block {
  padding-bottom: 70px;

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    margin: 0 0 10px 0;
    color: $directionsContentColor;
  }

  p {
    font-size: 14px;
    line-height: 140%;
    color: $directionsContentColor;
  }
}

.directions-img {
  width: 563px;
  height: 316px;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.directions-video {
  width: 84.537037037%;
  margin-bottom: 20px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  video {
    width: 100%;
  }

  &__play {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


@include touch {
  .directions {
    &__wrapper {
      max-width: 100%;
      margin: 0;
      flex-direction: column;
    }
  }

  .directions-content {
    padding: 25px 18px 0;
  }
}

@include mobile {
  .directions-title {
    font-size: 20px;
  }

  .directions-video {
    width: 100%;
  }

  .directions-block {
    padding-bottom: 60px;

    h2 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }

  .directions-img {
    width: 100%
  };

}

@import "directions/menu";
