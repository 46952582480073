.nav {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;

    li {
      list-style: none;
      font-weight: 500;
      line-height: 140%;
      color: #fff;
      margin-right: 40px;

      &:last-child {
        margin: 0;
      }

      a, span {
        color: #fff;
        text-decoration: none;
        padding: 12px;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}


@include mobile {
  .nav {
    ul {
      margin: 20px -10px 10px;
      display: flex;
      justify-content: space-between;
      width: calc(100% + 20px);

      li {
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        margin-right: 0;

        &.is-active {
          position: relative;

          a, span {
            color: $carrot;
          }

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -11px;
            width: 100%;
            height: 2px;
            background: $carrot;
          }
        }

        a, span {
          padding: 10px;
        }
      }
    }
  }
}
