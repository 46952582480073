.splide__arrows {
  display: flex;
  position: absolute;
  right: 0;
  top: -74px;

  .splide__arrow {
    opacity: 1;
    border: 0;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    background: #3772FF;
    box-shadow: 0px 0px 10px rgba(55, 114, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:first-child {
      margin-right: 10px;
    }

    &:hover {
        background: lighten(#3772FF, 10);
    }

    &:disabled {
      background: #323843;
      box-shadow: 0 0 10px rgba(50, 56, 67, 0.3);
      cursor: default;

      &:hover {
        background: #323843;
      }
    }

    svg {
      width: 6px;
      height: 12px;
    }
  }
}


@include mobile {
  .splide__arrows {
    top: -60px;
    .splide__arrow {
      width: 30px;
      height: 30px;

      svg {
        width: 5px;
        height: 11px;
      }
    }
  }
}
