.property-boolean {
  width: 34px;
  height: 34px;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 0 0 34px;

  svg {
    position: relative;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 34px;
    opacity: 0.3;
    background: $blue;
  }

  &:before {
    content: "";
    display: block;
    width: 21px;
    height: 21px;
    border-radius: 21px;
    background: linear-gradient(180deg, #1247FF 0%, #289FA7 100%);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@include touch {
  .property-boolean {
    width: 24px;
    height: 24px;

    &:before {
      width: 15px;
      height: 15px;
    }

    svg {
      width: 7.06px;
      height: 5.65px;
    }
  }
}

@include mobile {
  .property-boolean {
    width: 18px;
    height: 18px;

    &:before {
      width: 11px;
      height: 11px;
    }

    svg {
      width:  5.29px;
      height: 4.24px;
    }
  }
}
