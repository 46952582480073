.feature.is-online {
  position: absolute;
  right: $wrapperPadding;
  top: 136px;
  width: 28.402777777%;

  .feature {
    &__images {
      position: relative;
      width: 100%;

      img {
        width: 100%;
      }
    }

    &__description {
      max-width: 280px;
    }

    &__text {
      padding-left: 17.114914425%;
    }

    &__circle {
      bottom: -16.08910891%;// -65px;
      left: 7.33496325%;//30px;
    }
  }
}

@include tablets {
  .feature.is-online {
    width: calc(100% + 9px);
    padding-left: 55.327868852%;
    padding-top: 196px;
    left: 0;
    top: 0;
    right: auto;

    .feature {
      &__text {
        padding-left: 0;
      }

      &__circle {
        left: 58.060109289%;
        top: auto;
        bottom: 22px;
      }
    }
  }
}


@include mobile {
  .feature.is-online {
    position: relative;
    top: auto;
    left: auto;
    right: auto;

    .feature {
      &__text {
        padding: 0;
      }

      &__circle {
        left: 50%;
        transform: translateX(-50%);
        top: -66px;
        bottom: auto;
      }
    }
  }
}