.main-block .splashes {
  .is-blue {
    top: -436px;
    left: -409px;
  }

  .is-pink {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  .is-yellow {
    opacity: 0.5;
    top: -50px;
    right: -135px;
  }
}
