@mixin categoryBasic() {
  color: #111;
  border-bottom: $categoryBorder;
  font-weight: 600;
}

.plan-category {
    font-size: 18px;
    line-height: 140%;
    @include categoryBasic;

    .plan-property__first {
      padding-right: 15px;
    }

    &__first {
      .plan-property__first {
        border-right: 0;
      }
    }

    &:not(.plan-category__first) {
      .plan-property__first {
        padding-top: 50px;
      }
    }
}

@include touch {
  .plan-category {
    font-size: 16px;

    .plan-property__first {
      padding-right: 10px;
    }
  }
}


@include mobile {
  .plan-category-mobile {
    @include categoryBasic;
    font-size: 14px;
    line-height: 140%;
    padding: 10px 0;
    margin-bottom: 5px;

    &.is-first {
      padding-top: 20px;
    }

    &__properties {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
