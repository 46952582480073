.organizer-block .dots {
  .is-yellow {
    right: 2.5%;
    top: -8px;
  }

  .is-pink {
    right: 19.444%;
    bottom: 21px;
  }
}


@include touch {
  .organizer-block .dots {
    .is-pink {
      right: 38.2513661202%;
    }
  }
}

@include mobile {
  .organizer-block .dots {
    display: none;
  }
}
