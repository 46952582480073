.connectors {
  position: absolute;
  width: 100%;
  left: 0;
  top: 273px;

  &__line {
    height: 2px;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;

    &.is-left, &.is-right {
      width: 25%;
    }

    &.is-left {
      left: 25%;
    }

    &.is-right {
      right: 25%;
    }

    &.is-down {
      width: 2px;
      height: 128px;
      left: 50%;
      margin-left: -1px;

      &.is-arrowed {
        &:before {
          content: "";
          width: 14px;
          height: 11px;
          position: absolute;
          bottom: -3px;
          left: 50%;
          transform: translateX(-50%);
          background-image: url("../images/main/arrow.svg");
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
}

@include tablets {
  .connectors {
    top: 345px;
    transform: translateX(-30px);

    &__line {
      &.is-left {
        transform: rotate(60deg);
        transform-origin: right;
      }

      &.is-down {
        transform: rotate(30deg);
        transform-origin: top;
      }
    }
  }
}

@include mobile {
  .connectors {
    top: 568px;

    &__line {
      &.is-left, &.is-right {
        display: none;
      }
    }
  }
}