.splash {
  //opacity: 0.8;
  //filter: blur(200px);
  width: 541px;
  height: 541px;
  position: absolute;
  transform: translateZ(0); //for safari to use accelerators

  &.is-blue {
    //background: radial-gradient(50.97% 50.97% at 43.36% 45.49%, #014ECF 0%, rgba(1, 78, 207, 0) 64.28%);
    background: url("../images/splashes/blue.svg") no-repeat center center;
    background-size: 100% 100%;
  }

  &.is-pink {
    //background: radial-gradient(50.97% 50.97% at 43.36% 45.49%, #D72C4D 0%, rgba(96, 54, 190, 0.2) 64.28%);
    background: url("../images/splashes/pink.svg") no-repeat center center;
    background-size: 100% 100%;
  }

  &.is-yellow {
    //background: radial-gradient(50.97% 50.97% at 43.36% 45.49%, #D72C4D 0%, #FD792E 0.01%, rgba(243, 154, 39, 0.21) 64.28%);
    background: url("../images/splashes/yellow.svg") no-repeat center center;
    background-size: 100% 100%;
  }

  //&.is-purple {
  //  background: url("../images/splashes/purple.svg") no-repeat center center;
  //  background-size: 100% 100%;
  //}

  &.is-peachy {
    background: url("../images/splashes/peachy.svg") no-repeat center center;
    background-size: 100% 100%;
  }

  &.is-bright-yellow {
    background: url("../images/splashes/bright-yellow.svg") no-repeat center center;
    background-size: 100% 100%;
  }


  &.is-small {
    width: 395px;
    height: 395px;
  }

  &.is-medium {
    width: 957px;
    height: 957px;
  }

  &.is-large {
    width: 1017px;
    height: 1017px;
  }
}

.splashes {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .wrapper {
    position: relative;
    height: 100%;
  }
}
