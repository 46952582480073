.form-input {
  width: 100%;
  box-shadow: none;
  -webkit-appearance: none;
  background: rgba(0,0,0,0);
  border: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  outline: none;
  padding-bottom: 9px;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  color: rgba(255, 255, 255, .7);
  transition: border-bottom-color 0.5s ease;
  transform: translateZ(0);

  &::placeholder {
    color: rgba(255, 255, 255, .7);
  }

  &:active, &:focus {
    border-bottom-color: rgba(255, 255, 255, .5);
  }
}
