.plan-properties-mobile {
  display: none;
}

@include mobile {
  .plan-category-mobile-block {
    display: none;

    &.is-active {
      display: block;
    }
  }

  .plan-properties-mobile {
    display: block;
  }

  .plan-property-mobile {
    padding: 10px 0;
    font-size: 12px;
    line-height: 140%;
    color: #111;

    &__name {
      opacity: 0.5;
      margin-bottom: 5px;
    }

    &.is-boolean {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;

      .plan-property-mobile__name {
        opacity: 1;
        margin: 0;
      }
      .plan-property-mobile__value{
        margin-right: 10px;
      }
    }
  }
}
