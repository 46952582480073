.why-we__list {
  display: flex;
  color: #fff;
  margin-bottom: 58px;
}

.why-we__item {
  display: flex;
  flex-direction: column;
  font-style: normal;
  max-width: 282px;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  &-icon {
    font-size: 12px;
    width: 5em;
    height: 5em;
    box-shadow: 0 0 10px rgba(186, 46, 105, 0.3);
    border-radius: 5em;
    margin-bottom: 20px;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    margin-bottom: 0.3333333333333333em;
  }

  &-description {
    line-height: 140%;
  }

  &.is-reliability {
    .why-we__item-icon {
      background: linear-gradient(90deg, #DC2B49 0%, #4539D7 215.62%);

      &:before {
        background: url("../images/why-we/reliability.svg") no-repeat center center;
      }
    }
  }

  &.is-functionality {
    .why-we__item-icon {
      background: linear-gradient(180deg, #FD792E 0%, #F39A27 59.6%);

      &:before {
        background: url("../images/why-we/functionality.svg") no-repeat center center;
      }
    }
  }

  &.is-flexibility {
    .why-we__item-icon {
      background: linear-gradient(78.68deg, #FFB800 2.8%, #D739D0 94.95%);

      &:before {
        background: url("../images/why-we/flexibility.svg") no-repeat center center;
      }
    }
  }

  &.is-safety {
    .why-we__item-icon {
      background: linear-gradient(93.34deg, #4F3DFE -22.89%, #A93DFE 115.46%);

      &:before {
        background: url("../images/why-we/safety.svg") no-repeat center center;
      }
    }
  }
}

@import "./for-who";


.why-we-block .splashes {
  .splash {
    &.is-peachy {
      bottom: -340px;
      left: -263px;
    }

    &.is-blue {
      top: 28px;
      right: -94px;
    }
  }
}

@import "./dots";
@import "./mobile";
